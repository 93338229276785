<template>
  <router-view />
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      faviconUrl: '',
      settings: []
    };
  },
  methods: {
    changeFavicon(url) {
      this.faviconUrl = url;
    },
    updateFavicon() {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = this.faviconUrl;
    },
    async fetchSystemSettings() {
      try {
        const response = await axios.get('/api/v1/informacoes_sistema');
        this.settings = response.data.reduce((acc, cur) => {
          acc[cur.meta_field] = cur.meta_value;
          return acc;
        }, {});

        this.faviconUrl = this.settings.logo;
        this.updateFavicon();
      } catch (error) {
        console.error('Erro ao buscar as configurações do sistema:', error);
      }
    }
  },
  mounted() {
    this.fetchSystemSettings();
  }
}
</script>